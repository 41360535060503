import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Breadcrumb, Row, Col, Button, Space } from 'antd';
import { InfoCircleOutlined, PartitionOutlined } from '@ant-design/icons';

import { PageRoutes } from '../../conf/Global';
import { useEntityRegistry } from '../useEntityRegistry';
import { EntityType } from '../../types.generated';
import { navigateToLineageUrl } from '../lineage/utils/navigateToLineageUrl';
import useIsLineageMode from '../lineage/utils/useIsLineageMode';
import { ReactComponent as ChangeLogIcon } from '../../images/icon-change-log.svg';
import useIsChangeLogMode from '../entity/shared/containers/profile/utils/useIsChangeLogMode';

import utilService from '../../utils/util.service';

interface Props {
    type: EntityType;
    path: Array<string>;
    lineageSupported?: boolean;
    isProfilePage?: boolean;
    isBrowsable?: boolean;
}

/**
 * Responsible for rendering a clickable browse path view.
 */
export const LegacyBrowsePath = ({ type, path, lineageSupported, isProfilePage, isBrowsable }: Props) => {
    const entityRegistry = useEntityRegistry();
    const history = useHistory();
    const location = useLocation();
    const isLineageMode = useIsLineageMode();
    const isChangeLogMode = useIsChangeLogMode();

    const createPartialPath = (parts: Array<string>) => {
        const partialPath = parts.join('/');

        return utilService.encodeSpecialChars(partialPath);
    };

    const baseBrowsePath = `${PageRoutes.BROWSE}/${entityRegistry.getPathName(type)}`;

    const pathCrumbs = path.map((part, index) => (
        <Breadcrumb.Item key={`${part || index}`}>
            <Link
                to={
                    (isProfilePage && index === path.length - 1) || !isBrowsable
                        ? '#'
                        : `${baseBrowsePath}/${createPartialPath(path.slice(0, index + 1))}`
                }
            >
                {part}
            </Link>
        </Breadcrumb.Item>
    ));

    return (
        <Row className="mb-4" align="middle">
            <Col flex="auto">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={isBrowsable ? baseBrowsePath : '#'}>{entityRegistry.getCollectionName(type)}</Link>
                    </Breadcrumb.Item>
                    {pathCrumbs}
                </Breadcrumb>
            </Col>
            {lineageSupported && (
                <Col flex="none" className="f-mt-3-xs">
                    <Space align="center" size={0}>
                        <Button
                            type="text"
                            aria-label="Change Log"
                            className={isChangeLogMode ? 'btn-selected' : ''}
                            onClick={() =>
                                navigateToLineageUrl({ location, history, isLineageMode: false, isChangeLogMode: true })
                            }
                        >
                            <ChangeLogIcon
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                            <span>Change Log</span>
                        </Button>
                        <Button
                            type="text"
                            aria-label="Details"
                            className={!isLineageMode ? 'btn-selected' : ''}
                            onClick={() => navigateToLineageUrl({ location, history, isLineageMode: false })}
                        >
                            <InfoCircleOutlined aria-hidden="true" />
                            <span>Details</span>
                        </Button>
                        <Button
                            type="text"
                            aria-label="Lineage"
                            className={isLineageMode ? 'btn-selected' : ''}
                            onClick={() => navigateToLineageUrl({ location, history, isLineageMode: true })}
                        >
                            <PartitionOutlined aria-hidden="true" />
                            <span>Lineage</span>
                        </Button>
                    </Space>
                </Col>
            )}
        </Row>
    );
};
